import React from "react";

import fullLogo from "../../../../assets/images/full-logo.png";
import config from "../../../../config.json";

const freeTrialUrl = config["free-trial-url"];
const forgotPasswordUrl = config["forgot-password-url"];

import "./SignIn.scss";

const SignIn = ({ fallbackSignIn, loginError, isDarkMode }) => {
  const signIn = (event) => {
    event.preventDefault();
    fallbackSignIn(event.target[0].value, event.target[1].value);
  };

  return (
    <div className="signin">
      <div className="signin__intro content">
        Deliver your next draft faster and better. <br />
        Refine your writing and produce your best work with AI.
      </div>
      <form onSubmit={signIn}>
        <div className="signin__form">
          <div className="signin__fields">
            <input type="email" placeholder="Email:" required />
            <input type="password" placeholder="Password:" required />
          </div>
        </div>
        {loginError ? <div className="signin__error">Incorrect email or password.</div> : <></>}
        <div className="signin__submitLinks">
          <input type="submit" value="Sign In" className="signin__submit" />
          <a href={forgotPasswordUrl}>Forgot your password?</a>
        </div>
        <div className="signin__account">
          <div className="signin__intro">Don't have an account?</div>
          <a href={freeTrialUrl}>Sign up for a free trial</a>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
